import Vue from 'vue'
import Vuex from 'vuex'
import Notifications from '@/store/notifications/notifications'
import CatalogApiRequest, { CatalogIdentity } from './catalogApiRequest'

Vue.use(Vuex)

interface CustomerHistoryState {
  history: Array<CatalogIdentity>
  dataLoading: boolean
}

export default new Vuex.Store({
  state: (): CustomerHistoryState => ({
    history: [],
    dataLoading: true,
  }),
  mutations: {
    setCatalogHistory(state: any, data: any) {
      state.dataLoading = false
      state.history = data
    },
  },
  actions: {
    getCatalogHistory(context: any, catalogId: string) {
      CatalogApiRequest.getCatalogHistory(catalogId).then(results => {
        context.commit('setCatalogHistory', results.data.reverse())
      }).catch(error => {
        Notifications.commit('addError', error.response.data.msg ?? 'catalogHistory.errorResponse', { root: true })
      })
    },
  },
})
