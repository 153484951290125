<template>
  <div>
    <b-card v-if="canShowLastImport()" :title="$t('catalogHistory.lastImport')">
      <vs-col vs-w="12" class="mb-2">
        <p>
          {{ $t('catalogHistory.yoursCatalogLastImportDesc') }}
          <strong>{{ getLastImportStatusDescription() }}</strong> ({{ getLastImportDate() }} UTC)
        </p>
        <p> {{ $t('catalogHistory.yoursCatalogLastImportDescProducts') }} <strong>{{ getLastImportProductsCount() }}</strong></p>
        <p>
          <check-circle-icon /> &nbsp;
          <strong>{{ getLastImport().correctProductsCount }}</strong>
          {{ $t('catalogHistory.correctProducts') }}
        </p>
        <p>
          <alert-circle-icon /> &nbsp;
          <strong>{{ getLastImport().incorrectProductsCount }}</strong>
          {{ $t('catalogHistory.incorrectProducts') }}
        </p>
      </vs-col>
    </b-card>
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
      <b-card :title="$t('catalogHistory.header')">
        <div ref="container">
          <vs-row class="mb-1">
            {{ $t('catalogHistory.shortDescription') }}
          </vs-row>
        </div>
        <vs-table class="stretchTableOne" :data="catalogHistory">
          <template slot="thead">
            <vs-th v-for="(name, columnIndex) in columnNamesView" :key="columnIndex">
              <span>{{ $t('catalogHistory.tableHeaders.' + name) }}</span>
            </vs-th>
          </template>
          <vs-tr v-for="(item,key) in catalogHistory" :key="key">
            <vs-td>
              {{ item.lastTime }} UTC
            </vs-td>
            <vs-td>
              {{ item.owner }}
            </vs-td>
            <vs-td>
              {{ getStatusDescription(item.status) }}
            </vs-td>
            <vs-td>
              {{ item.correctProductsCount }}
            </vs-td>
            <vs-td>
              {{ item.incorrectProductsCount }}
            </vs-td>
          </vs-tr>
        </vs-table>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import CatalogHistory from '@/store/catalog/catalogHistory'
import { CheckCircleIcon, AlertCircleIcon } from 'vue-feather-icons'

export default {
  components: { CheckCircleIcon, AlertCircleIcon },
  data() {
    return {
      columnNamesView: ['date', 'owner', 'status', 'correctProductsCount', 'incorrectProductsCount'],
    }
  },
  computed: {
    catalogHistory() {
      return CatalogHistory.state.history
    },
    dataLoading() {
      return CatalogHistory.state.dataLoading
    },
  },
  mounted() {
    CatalogHistory.dispatch('getCatalogHistory', this.$route.params.catalogId)
  },
  methods: {
    canShowLastImport() {
      return !this.dataLoading && CatalogHistory.state.history.length > 0
    },
    getLastImport() {
      return CatalogHistory.state.history[0]
    },
    getLastImportDate() {
      return this.getLastImport().lastTime
    },
    getLastImportProductsCount() {
      return this.getLastImport().correctProductsCount + this.getLastImport().incorrectProductsCount
    },

    getLastImportStatusDescription() {
      const { status } = this.getLastImport()
      let translateStatus = ''
      switch (status) {
        case 0: translateStatus = 'in_progress'; break
        case 1: translateStatus = 'ok'; break
        case 2: translateStatus = 'error'; break
        default: translateStatus = 'unknown'
      }
      return this.$t(`catalogHistory.laststatus.${translateStatus}`)
    },

    getStatusDescription(status) {
      let translateStatus = ''
      switch (status) {
        case 0: translateStatus = 'in_progress'; break
        case 1: translateStatus = 'ok'; break
        case 2: translateStatus = 'error'; break
        default: translateStatus = 'unknown'
      }
      return this.$t(`catalogHistory.status.${translateStatus}`)
    },
  },
}
</script>
